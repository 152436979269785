<template>
  <div class="register">
    <nav class="register-nav">
      <div class="register-nav-arrow" @click="onBackClick"></div>
    </nav>
    <div class="register-tip">手机号注册</div>
    <van-form @submit="onSubmit">
      <!-- <van-field
        v-model="username"
        name="username"
        label="名称"
        placeholder="请输入名称"
      /> -->
      <van-field
        v-model="mobile"
        type="text"
        name="mobile"
        label="手机号"
        placeholder="手机号"
        :rules="[{ required: true, message: '请填写手机号' }]"
      />
      <van-field
        v-model="password"
        :type="passwordType"
        name="password"
        label="密码"
        placeholder="请输入8-20位字母或数字"
        :rules="[
          { validator: validatorpwd, message: '请输入8-20位字母或数字' },
        ]"
      >
        <template #right-icon>
          <div @click="showHidePwd">
            <van-icon name="closed-eye" v-if="ishide" />
            <van-icon name="eye-o" v-else />
          </div>
        </template>
      </van-field>
      <van-field
        v-model="sms"
        name="sms"
        center
        clearable
        label="验证码"
        placeholder="请输入验证码"
      >
        <template #button>
          <van-button
            v-if="issend"
            size="small"
            type="primary"
            @click.prevent="sendSMS"
            >获取验证码</van-button
          >
          <van-button v-else size="small" type="primary" disabled
            >重新获取({{ seedms }})
          </van-button>
        </template>
      </van-field>

      <van-field
        v-model="inviteCode"
        type="text"
        name="inviteCode"
        label="推荐码"
        placeholder="选填内容"
      />

      <div class="page-check">
        <div
          :class="['icon', isCheck ? 'isCheck' : '']"
          @click="changeCheck"
        ></div>
        我已阅读并同意 <span>《用户协议》</span> 和 <span>《隐私政策》</span>
      </div>
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"
          >注册</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import qs from "qs";
import { userRegister, getcode } from "@api/user.js";
export default {
  name: "Register",
  data() {
    return {
      // 用户名
      username: "",
      //   手机
      mobile: "",
      //   密码
      password: "",
      //  验证码
      sms: "",
      // 校验的Id
      codeId: "",
      // 倒计时
      seedms: 60,
      //  是否成功发送验证码
      issend: true,
      //   是否勾选
      isCheck: false,
      //  控制查看密码
      ishide: true,
      // 控制密码输入的类型
      passwordType: "password",
      // 推荐码
      inviteCode:"",
    };
  },
  mounted() {},
  methods: {
    // 查看显示隐藏密码
    showHidePwd() {
      this.ishide = !this.ishide;
      this.passwordType = this.ishide ? "password" : "text";
    },
    onBackClick() {
      this.$router.go(-1);
    },
    //   校验密码
    validatorpwd(val) {
      return /^(?![0-9]+$)(?![a-zA-Z]+$)[a-zA-Z\d]{8,20}$/.test(val);
    },
    changeCheck() {
      this.isCheck = !this.isCheck;
    },
    // 验证码倒计时
    getTime() {
      let num = setInterval(() => {
        this.issend = false;
        this.seedms -= 1;
        if (this.seedms < 1) {
          clearInterval(num);
          if (this.seedms < 1) {
            this.issend = true;
            this.seedms = 60;
          }
        }
      }, 1000);
    },
    // 注册
    async onSubmit(value) {
      // 判断用户是否勾选协议
      if (!this.isCheck) {
        this.$toast("请阅读并同意《用户协议》和 《隐私政策》");
        return;
      }
      const { mobile, sms: code, password,inviteCode } = value;
      const codeId = this.codeId;
      const result = await userRegister(
        qs.stringify({ mobile, code, codeId, password,inviteCode })
      );
      this.validateLogin(result);
    },
    // 发送验证码
    async sendSMS() {
      // 校验手机号码
      if (this.mobile === "") {
        this.$toast("请填写手机号");
      } else {
        const data = {
          mobile: this.mobile,
          type: 3,
        };
        const result = await getcode(qs.stringify(data));

        if (result.code === 0) {
          this.getTime();
          this.codeId = result.data.codeId;
        } else {
          this.$toast(result.msg);
        }
      }
    },
    // 鉴权
    validateLogin(result) {
      if (result.code === 0) {
        // 注册成功提示用户去登录
        this.$dialog
          .alert({
            title: "注册成功!",
            message: `用户\n +86 ${this.mobile}`,
            confirmButtonText: "立即使用",
            confirmButtonColor: "#4588FF",
          })
          .then(() => {
            this.$router.replace("/login");
          });
        // this.$dialog
        //   .alert({
        //     title: "注册成功",
        //     message: `用户\n +86 ${this.mobile}`,
        //     confirmButtonText: "立即使用",
        //     confirmButtonColor: "#4588FF",
        //   })
        //   .then(() => {
        //     console.log(1231);
        //   });
        //  注册成功需要提示用户去登录
        // // 保持用户token
        // this.$store.commit("Login_succ", result.data);
        // // 需要判断当前的登录设备是安卓还是ios
        // //
        // if (window.android) {
        //   // 安卓
        //   //   window.android.postMessage();
        //   //   安卓通信 传递一个方法
        //   const data = {
        //     action: "login",
        //     data: result.data,
        //   };
        //   let jsonStr = JSON.stringify(data);
        //   window.android.postMessage(jsonStr);
        // } else if (window.webkit) {
        //   // ios
        //   console.log("isiOS");
        // } else {
        //   console.log("pc");
        // }
        // this.$router.replace("/");
      } else {
        //   失败提示消息
        this.$toast(result.msg);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-dialog__header {
  color: #4588ff;
}
.register {
  position: relative;
  height: 100vh;
  width: 100%;
  //   padding: 0 30px;
  font-size: 14px;
  overflow: hidden;

  // 登录导航
  &-nav {
    width: 100%;
    height: 30px;
    // background-color: #fdfd55;
    display: flex;
    align-items: center;
    &-arrow {
      margin-left: 30px;
      width: 8px;
      height: 14px;
      background-image: url("~@image/arrowdown2.png");
      background-size: 8px 14px;
      background-repeat: no-repeat;
    }
  }
  &-tip {
    padding-left: 15px;
    margin: 45px 0;
    font-size: 21px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    line-height: 14px;
  }
  .page-check {
    display: flex;
    align-items: center;
    color: #333333;
    margin-top: 20px;
    padding: 0 30px;
    span {
      color: #4588ff;
    }
    .icon {
      width: 14px;
      height: 14px;
      background-image: url("~@image/select-n.png");
      background-size: 100% 100%;
      margin-right: 15px;
      &.isCheck {
        background-image: url("~@image/select-h.png");
      }
    }
  }
}
</style>
